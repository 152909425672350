.App {
  text-align: center;
}

.sandbox-mode {
  position: relative;
}

.sandbox-mode::after {
  content: 'SANDBOX';
  position: fixed;
  top: -2px;
  left: 280px;
  background: orange;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  color: white;
  z-index: 100000;
}



.dashboard {
  display: flex;
  height: 100vh;
}

.main-content {
  background: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
}

input[type="range"] {
  width: 100%;
}

form {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

form label {
  display: block;
  margin-bottom: 10px;
}

form input[type="number"] {
  width: 60px;
  margin-left: 10px;
}

form button {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

form button:hover {
  background-color: #0056b3;
}

.sidebar-controls {
  margin-top: 20px;
  padding: 10px;
  background-color: #333;
  border-radius: 5px;
}

.sidebar-controls label {
  display: block;
  color: #fff;
  text-align: left;
  font-size: 10px;
  font-weight: bold;
  color: #666;
}

.sidebar-controls input[type="range"] {
  width: 100%;
}

.sidebar-controls span {
  color: #999;
  font-size: 12px;
  font-weight: bold;
}
