.details-view {
  padding: 0 20px;
  width: 99%;
}

.dataset-details h2 {
  text-align: left;
}

ul.details-heuristics {
  display: flex;
  flex-direction: row;
  background: #fff;
  z-index: 1;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #eee;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  transition: left 0.3s ease-in-out;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100%;
  justify-content: space-between;
  list-style-type: none;
  padding-left: 0;
}

ul.details-heuristics li {
  font-weight: normal;
  margin: 0 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

ul.details-heuristics li span.label-container {
  display: flex;
  align-items: center;
}
ul.details-heuristics li span.color-square {
  width: 8px;
  height: 8px;
  margin-right: 5px;
  border-radius: 100%;
}

ul.details-heuristics li label {
  font-size: 10px;
  font-weight: bold;
  display: block;
  text-align: left;
  text-transform: uppercase;
  color: #999;
}

ul.details-heuristics li .score-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

ul.details-heuristics li .score-container span {
  color: #333;
  font-weight: bold;
}
