.co-pilot-avatar {
  position: fixed;
  bottom: 75px;
  right: 20px;
  width: 30px;
  height: 30px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease;
  padding: 15px;
  border-radius: 50%;
  background-color: #010326;
}

.co-pilot-avatar::after {
  content: ".";
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  border: 2px solid transparent; /*2*/
  background: linear-gradient(
      to right,
      #e9b026,
      #ff8830,
      #ff5951,
      #ff227a,
      #e500a8,
      #d400b4,
      #be00c0,
      #a200cd,
      #c300b2,
      #d70098,
      #e20081,
      #e62e6e
    )
    border-box; /*3*/
  mask: /*4*/
    linear-gradient(#000 0 0) padding-box,
    linear-gradient(#000 0 0);
  mask-composite: exclude; /*5*/
}

.co-pilot-avatar:hover {
  transform: scale(1.1);
}

.co-pilot-avatar img {
  min-width: 100%;
}
