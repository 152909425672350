.view-toggle {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.view-toggle button {
  padding: 8px 16px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.view-toggle button:hover {
  background-color: #e0e0e0;
}

/* Responsive design */
@media screen and (max-width: 768px) {
  .heuristic-container {
    padding: 10px;
  }

  .heuristic-table th,
  .heuristic-table td {
    padding: 8px 10px;
    font-size: 13px;
  }

  .heuristic-treemap-container {
    height: 400px;
  }
}

/* src/components/HeuristicTable.css */
.heuristic-table-container {
  overflow-y: auto;
  height: calc(100vh - 120px); /* Adjust 40px based on your layout */
  margin: 10px 30px;
  display: flex;
  flex-direction: column;
  transform: scaleX(1);
  transition: transform 0.3s ease-in-out;
}

div[data-testid^="node."] {
  border-radius: 10px;
}
div[data-testid^="node."] span {
  font-weight: 500;
    font-size: 90% !important;
    text-align: center;
    white-space: normal !important;
    margin: 0 !important;
    width: 80% !important;
    left: -40% !important;
    top: -3% !important;
}
.third-pane-open .heuristic-table-container {
  transform: scaleX(0.95);
}

.heuristic-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 4px;
  font-size: 11px;
  flex-grow: 1;
}

.heuristic-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
}

.heuristic-table th,
.heuristic-table td {
  border: none;
  padding: 8px;
  text-align: left;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.heuristic-table td {
  font-weight: 500;
  font-size: 170%;
  text-align: center;
}

.heuristic-table td:first-child {
  font-weight: bold;
  font-size: 100%;
  text-align: right;
}

.heuristic-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.heuristic-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.heuristic-table tbody tr:hover {
  background-color: #f0f0f0;
}

/* Add CSS styles for the treemap layout */
.heuristic-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.view-toggle {
  margin-bottom: 20px;
}

.heuristic-treemap-container {
  width: 100%;
  height: 724px;
}

/* score table page */

.heuristic-table-container.scoreTable {
  display: block;
  width: 80%;
}

.heuristic-table-container.scoreTable .heuristic-table td:first-child {
  font-weight: bold;
  font-size: 135%;
  text-align: left;
  width: 30%;
}

.heuristic-table-container.scoreTable .heuristic-table th.sortable {
  position: relative;
  cursor: pointer;
}

.heuristic-table-container.scoreTable .heuristic-table th.sortable svg {
  font-size: 15px;
  float: right;
}

.heuristic-table-container.scoreTable .heuristic-table th.sortable .sort-icon {
  visibility: hidden;
}

.heuristic-table-container.scoreTable
  .heuristic-table
  th.sortable:hover
  .sort-icon {
  visibility: visible;
}

.heuristic-table-container.scoreTable
  .heuristic-table
  th.sortable.sorted
  .sort-icon {
  visibility: visible;
}
