.note-card {
  border-radius: 8px;
  padding: 16px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;
  background: lightyellow;
  text-align: left;
  margin-bottom: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  background: lightyellow;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid #fff;
}
.no-notes {
  text-align: center;
  padding: 40px;
  font-size: 1.2em;
}
.note-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  transform: translateY(-2px);
  border: 1px solid #666;
}

.note-card.no-notes:hover {
  box-shadow: none;
  transform: translateY(0);
  border: transparent;
  cursor: default;
}

.note-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 10px;
}

.note-heuristic {
  font-weight: 600;
  color: #444;
  font-size: 0.9em;
}

.expand-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease;
  color: #666;
  padding: 4px;
}

.note-card:hover .expand-button,
.note-card.expanded .expand-button {
  opacity: 0.6;
}

.expand-button:hover {
  opacity: 1;
  color: #010326;
  transform: scale(1.1);
}

.note-content {
  color: #333;
  margin: 0;
  font-size: 0.8rem;
  line-height: 1.4;
}

.expanded {
  z-index: 1;
}
