.controls,
.compare-controls {
  position: fixed;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  /* box-shadow: 32px 0px 56px rgba(0, 0, 0, 0.05); */
  transition: left 0.3s ease-in-out;
  border-top: 1px solid #eee;
  overflow: hidden;
  bottom: 0;
  height: 55px;
}

.step-buttons {
  flex-grow: 1;
  margin: 0 10px;
  border-left: 1px solid #eee;
  align-content: baseline;
  display: flex;
  height: 70px;
  line-height: 68px;
  min-width: 189px;
}

.step-button {
  background: none;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  padding: 0 10px;
  color: #333;
}

.step-button:hover {
  color: #007bff;
}

.play-pause-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-right: 10px;
  color: #333;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transition: background-color 0.3s ease;
  margin: 0 10px;
}

.period-scrubber {
  flex-grow: 1;
  margin: 0 10px;
}

.period-label {
  min-width: 100px;
  text-align: center;
  margin: 0 10px;
  font-size: 1em;
  font-weight: bold;
}

.sidebar-controls {
  border-top: 1px solid #ddd;
  text-align: start;
  position: absolute;
  bottom: 76px;
  background: #fff;
  z-index: 1;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #eee;
  width: 160px;
  left: 280px;
  transition: left 0.3s ease-in-out;
}

.sidebar-closed .sidebar-controls {
  left: 20px;
}

.dashboard.third-pane-open .sidebar-controls {
  right: 320px;
}

.chart-size-control {
  display: flex;
  margin-top: 0;
  align-items: center;
}

.chart-size-control input[type="range"] {
  flex-grow: 1;
  margin-right: 10px;
}

.dashboard {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.main-content {
  flex-grow: 1;
  display: flex;
  overflow-y: auto;
  padding-bottom: 80px;
  transition: margin-left 0.3s ease-in-out;
  flex-direction: column;
  align-items: center;
}

.sidebar-open .main-content {
  margin-left: 250px; /* Adjust this value to match your sidebar width */
}

.sidebar-closed .main-content {
  margin-left: 0;
}

.page-view {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 210px; /* Or whatever width you prefer */
  background-color: #010326; /* Or your preferred background color */
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
  display: flex;
  flex-direction: column;
  padding: 25px 25px 0;
}

.sidebar-closed .sidebar {
  overflow: visible;
}

.sidebar nav ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
}

.sidebar nav ul li {
  margin-bottom: 10px;
}

.sidebar nav ul li a {
  text-decoration: none;
  color: #fff;
}

.sidebar-logo {
  background-size: cover;
  background-position: 50% 50%;
  text-indent: -9999em;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 5px;
  left: 10px;
}

.sidebar-logo svg {
  width: auto;
  height: 30px; /* Adjusted height to make room for text */
  display: block;
  max-width: 100%;
}

.framework-text {
  font-weight: bold;
  color: black;
  margin-left: 10px; /* Space between logo and text */
  font-size: 12px; /* Adjust as needed */
  white-space: nowrap; /* Prevent text from wrapping */
}

.sidebar nav {
  flex-grow: 0.2;
}

.sidebar nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar nav ul li {
  padding: 0;
  margin-bottom: 0px;
}

.sidebar nav ul.nested-pages {
  margin-left: 9px;
  background: hsl(220deg 12.63% 25.52% / 17%);
  border-radius: 5px;
  padding: 5px 10px 5px;
}

.sidebar nav ul li.nav-header {
  padding: 0 10px;
  text-transform: uppercase;
  font-size: 11px;
}

.sidebar nav ul li a {
  text-decoration: none;
  color: #9c9292;
  font-size: 13px;
  display: block;
  width: 100%;
  font-weight: 400;
  padding: 8px;
  white-space: nowrap;
}

.sidebar nav ul li ul.nested-pages li a {
  white-space: nowrap;
}

.sidebar nav ul li ul.nested-pages li:hover a {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar nav ul li a.active {
  /* background-color: #1b6bfd; */
  color: #fff;
  border-radius: 10px;
}

.sidebar nav ul li a svg {
  font-size: 11px;
}

.sidebar nav ul li a:hover {
  text-decoration: none;
  color: #fff;
}

.sidebar-closed .sidebar {
  transform: translateX(-260px);
}

.sidebar-open .controls,
.sidebar-open .compare-controls {
  left: 260px; /* Adjust this value to match your sidebar width */
}

.sidebar-closed .controls,
.sidebar-closed .compare-controls {
  left: 0;
}

.sidebar-toggle {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 40px;
  height: 40px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #888;
  transition: background-color 0.3s ease;
  z-index: 10;
  background: transparent;
}

.sidebar-toggle:hover {
  color: #eee;
}

.sidebar-closed .sidebar-toggle {
  position: absolute;
  top: 15px;
  right: -40px;
  width: 40px;
  height: 40px;
  background-color: #eee;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  transition: background-color 0.3s ease;
  z-index: 10;
}

/* .page-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  color: #ddd;
} */

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  color: #ddd;
}

.page-item input {
  flex-grow: 1;
  margin-right: 10px;
}

.icon-container {
  display: flex;
  align-items: center;
}

/* Add to Sidebar.css */
.nav-link-container {
  position: relative;
  display: flex;
  align-items: center;
}

.color-picker-trigger {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 4px 8px;
  margin-left: 8px;
}

.color-picker-dropdown {
  position: absolute;

  background: white;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.color-picker-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.color-input-row {
  display: flex;
  align-items: center;
  gap: 4px;
}

.color-input-row button {
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.color-input-row button:first-of-type {
  background: #007bff;
  color: white;
}

.color-input-row button:last-of-type {
  background: #6c757d;
  color: white;
}

.floating-color-picker {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15);
  padding: 16px;
}

.drag-handle {
  cursor: move;
  padding: 4px;
  text-align: center;
  border-bottom: 1px solid #eee;
  margin-bottom: 8px;
}

.drag-handle-icon {
  color: #666;
  font-size: 20px;
  line-height: 1;
}

.color-picker-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.color-input-row {
  display: flex;
  align-items: center;
  gap: 4px;
}

.color-input-row button {
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.color-input-row button:first-of-type {
  background: #007bff;
  color: white;
}

.color-input-row button:last-of-type {
  background: #6c757d;
  color: white;
}

.menu-toggle {
  color: #ccc;
  font-size: 10px;
  cursor: pointer;
}

.menu-toggle > svg {
  margin: 0 -10px 0;
}

.edit-icon,
.delete-icon {
  margin-left: 10px;
  cursor: pointer;

  color: #777;
  font-size: 12px;
}

.edit-icon:hover,
.fa-eye-dropper:hover {
  color: #fff;
}

.delete-icon:hover {
  color: #900;
}

/* Hide icon-container initially */
.icon-container {
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0.2s,
    opacity 0.2s ease-in-out;
  position: absolute;
  right: 30px;
  border-radius: 2px;
}

/* Show icon-container when its specific parent li is hovered */
.page-item > .page-header:hover > .icon-container {
  visibility: visible;
  opacity: 1;
}

/* Make sure the parent's icon-container doesn't show when hovering nested items */
.page-item ul.nested-pages li:hover > .page-header > .icon-container {
  visibility: visible;
  opacity: 1;
}

.page-item ul.nested-pages li:hover > .icon-container {
  visibility: hidden;
  opacity: 0;
}

.home-page {
  padding: 20px;
}

.home-page h1 {
  text-align: center;
  margin-bottom: 30px;
}

.chart-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
  padding: 20px;
}

.compare-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.compare-chart-grid {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

.nav-header {
  margin-top: 40px;
  font-weight: bold;
  font-size: 12px;
  color: #555;
}

.settings-toggle {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings-menu {
  margin-left: 20px;
}

/* Add any additional styling as needed */

.sidebar {
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: absolute;
  z-index: 2;
  overflow-x: hidden;
  padding-top: 20px;
}

.user-info {
  margin-top: auto;
  padding: 13px 13px 6px 13px;
  display: flex;
  align-items: center;
  color: #fff;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  border-top: 1px solid #333;
  position: sticky;
  bottom: 5px;
  margin-left: -24px;
  background: transparent;
  margin-right: -24px;
  background-color: #010326;
}

.user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-avatar-icon {
  font-size: 24px;
  margin-right: 10px;
}

.user-name {
  font-weight: 500;
  font-size: 13px;
}
.more-icon {
  margin-left: auto;
  margin-right: 0;
  position: relative;
  padding: 0 10px;
}

.flyout-menu {
  position: absolute;
  bottom: 30px;
  background: #fff;
  padding: 0 10px 10px 10px;
  border-radius: 6px;
  right: -10px;
  width: 100px;
  min-height: 100px;
}

/* Add this to your CSS file */

/* Position the flyout menu relative to its container */
.more-icon-container {
  position: relative;
  margin-left: auto;
}

.more-icon {
  cursor: pointer;
  /* Adjust styles as needed */
}

.more-icon-container .flyout-menu {
  bottom: 110px;
  background: #fff;
  padding: 10px;
  border-radius: 6px;
  width: 150px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: -130px;
  left: -150px;
  z-index: 10000;
}

.logout-button {
  background: none;
  border: none;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.logout-button:hover {
  color: #007bff;
}

.logout-button svg {
  margin-right: 8px;
}

/* Add to Sidebar.css */
.color-picker-portal {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15);
  padding: 16px;
}

.drag-handle {
  cursor: move;
  padding: 4px;
  text-align: center;
  user-select: none;
}

.drag-icon {
  color: #666;
}

.picker-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.color-input-row {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}

.color-input-row button {
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Update CSS */
.color-picker-modal {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15);
  z-index: 9999;
}

.picker-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #eee;
}

.close-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #666;
}

.picker-content {
  padding: 16px;
}

.color-input-row {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
}
