/* light box */
/* Lightbox styles */
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.5s ease-in-out;
  flex-direction: column;
}

.lightbox .lightbox-logo-contain {
  background: #fff;
  padding: 20px 20px 0;
  border-radius: 100%;
  margin-bottom: -40px;
  position: relative;
}

.lightbox .logo {
  height: 48px;
  margin: 0;
}

.lightbox-content {
  color: #000;
  padding: 50px 50px 30px;
  text-align: center;
  border-radius: 8px;
  animation: scaleIn 0.3s ease-in-out;
  background: #fff;
  font-weight: bold;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 35%;
  flex-direction: column;
}

.loading-spinner {
  font-size: 2rem;
  color: #007bff;
  margin-right: 0.5rem;
}

.close-lightbox-btn {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
}

.close-lightbox-btn:hover {
  background: #0056b3;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

/* Add to Lightbox.css */
.confirm-clone-btn {
  margin-top: 15px;
  padding: 8px 16px;
  background: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button {
  margin-top: 15px;
  padding: 8px 16px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.confirm-clone-btn:disabled {
  background: #cccccc;
  cursor: not-allowed;
}
