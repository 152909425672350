.compare-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 20px;
  width: 90%;
  transition: margin-right 0.3s ease-in-out;
}

.compare-container.third-pane-open {
  margin-right: 300px; /* Should match the third pane width */
}

.compare-chart-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  flex-grow: 1;

  width: 100%;
}

.compare-chart-item {
  aspect-ratio: 1 / 1;
  width: 100%;
}

.compare-chart-item .metrics {
  width: 90%;
  margin-left: 10px;
}

.compare-chart-item h3 {
  margin-top: 0;
  margin-bottom: 10px;
  text-align: center;
}

.compare-spider-chart h2 {
  font-size: 14px;
  margin: 0 0 5px 0;
  height: auto !important;
}

.compare-spider-chart .chart-container {
  flex-grow: 1;
}

/* ... (keep existing styles for controls) */
