.header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  flex-wrap: nowrap;
  width: 100%;
  padding: 8px 20px;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
  align-content: center;
  justify-items: center;
  flex-direction: row;
  box-sizing: border-box;
}

.header-bar h1 {
  font-size: 1.3rem;
  padding-left: 10px;
  transition: padding-left 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header-bar h1 span {
  font-size: 0.8rem;
  color: #000;
}

.sidebar-closed .main-content .header-bar h1 {
  padding-left: 35px;
}

.current-page {
  font-weight: bold;
}

.toggle-switch {
  display: flex;
  background-color: #f0f0f0;
  border-radius: 20px;
  overflow: hidden;
}

.toggle-link {
  background: none;
  border: 1px solid #010326;
  border-radius: 10px 0 0 10px;
  border-right: 0;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.toggle-link + .toggle-link {
  border-radius: 0;
  border-left: 1px solid #010326;
}

.toggle-link:last-child {
  border: 1px solid #010326;
  border-radius: 0 10px 10px 0;
  border-left: 1px solid #010326;
}

.toggle-link.active {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
}

.toggle-link:hover:not(.active) {
  background-color: #e0e0e0;
}

.actions {
  display: flex;
  width: auto;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.actions button {
  padding: 5px 20px;
  background-color: #007bff;
  cursor: pointer;
  transition: background-color 0.3s;
  border: 1px solid #010326;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  margin-left: 10px;
}

.actions button.secondary {
  background: none;
  color: #010326;
}

.actions button.secondary:hover {
  background: #e0e0e0;
}
