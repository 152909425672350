.dashboard {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.dashboard.third-pane-open .controls {
  margin-right: 0;
  transition: margin-right 0.3s ease-in-out;
}

.dashboard.third-pane-open .controls {
  margin-right: 300px;
}

.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  transition: margin-right 0.3s ease-in-out;
  overflow-y: auto;
}

.dashboard.third-pane-open .main-content {
  margin-right: 300px; /* Adjust this value based on your desired third pane width */
}

.data-point-info {
  margin-top: 40px;
}

.bottom-controls-wrapper {
  margin-top: auto;
  transition: margin-right 0.3s ease-in-out;
}

.dashboard.third-pane-open .bottom-controls-wrapper {
  margin-right: 300px; /* Should match the third pane width */
}
