.add-release-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.add-release-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.add-release-form .release-input {
  padding: 10px;
  font-size: 16px;
}

.add-release-form button {
  padding: 10px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.add-release-form button:hover {
  background-color: #0056b3;
}

.clone-option {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0 0 .75rem;
}

form .clone-option label {
  margin-bottom: 0;
  margin-left: .25rem;
}