.jira-links-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.links-table-container.scoreTable {
  display: block;
  width: 80%;
}

.links-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 4px;
  font-size: 11px;
}

.links-table thead {
  position: sticky;
  top: 69px;
  z-index: 1;
  background-color: #fff;
}

.links-table th,
.links-table td {
  border: none;
  padding: 8px;
  text-align: left;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.links-table td {
  font-weight: 500;
  font-size: 135%;
}

.links-table td:first-child {
  font-weight: bold;
  text-align: left;
  width: 30%;
}

.links-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.links-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.links-table tbody tr:hover {
  background-color: #f0f0f0;
}

.links-table-container.scoreTable .links-table th.sortable {
  position: relative;
  cursor: pointer;
}

.links-table-container.scoreTable .links-table th.sortable svg {
  font-size: 15px;
  float: right;
}

.links-table-container.scoreTable .links-table th.sortable .sort-icon {
  visibility: hidden;
}

.links-table-container.scoreTable .links-table th.sortable:hover .sort-icon {
  visibility: visible;
}

.links-table-container.scoreTable .links-table th.sortable.sorted .sort-icon {
  visibility: visible;
}

/* Links specific styles */
.links-table td a {
  color: var(--color-primary);
  text-decoration: none;
  transition: color 0.2s ease;
}

.links-table td a:hover {
  text-decoration: underline;
}

/* Responsive design */
@media screen and (max-width: 768px) {
  .links-container {
    padding: 10px;
  }

  .links-table th,
  .links-table td {
    padding: 8px 10px;
    font-size: 13px;
  }
}
