/*==================== CSS VARIABLES ====================*/
:root {
  /* Colors */
  --color-primary: #e5209e;
  --color-secondary: #25324d;
  --color-accent: #fbd039;
  --color-background: #cedbe8;
  --color-text: #999;
  --color-text-light: #edf2f7;
  --color-white: #fff;

  /* Gradients */
  --gradient-primary: linear-gradient(
    to right,
    #e9b026,
    #ff8830,
    #ff5951,
    #ff227a,
    #e500a8,
    #d400b4,
    #be00c0
  );

  /* Spacing */
  --spacing-xs: 15px;
  --spacing-sm: 20px;
  --spacing-md: 40px;
  --spacing-lg: 80px;
  --spacing-xl: 100px;
  --spacing-gap: 5%;

  /* Typography */
  --font-size-xs: 0.8rem;
  --font-size-sm: 1rem;
  --font-size-md: 1.3rem;
  --font-size-lg: 2rem;
  --font-size-xl: 4rem;
  --font-weight-regular: 400;
  --font-weight-bold: 700;
}

header .header-top {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}

.home button {
  padding: 10px 20px;
  background-color: #1a202c;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}
.home button a {
  color: #fff;
}

.home button:hover a,
.home button:hover a:hover {
  color: #fff;
}

.home button:hover {
  background-color: #e5209e;
  color: #fff;
}

.home {
  text-align: center;
}

.home h1,
.home h2,
.home h3 {
  color: #1a202c;
  font-weight: 700;
  line-height: 1.2;
}

.home h2 {
  margin: 0;
}

.highlight {
  color: #2f855a;
}

/* Navbar Styles */
main .header {
  background-color: #fff;
  color: #010326;
  padding: 10px 40px;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #010326;
  padding-right: 10px;
}

.logoContain {
  color: #fff;
  text-decoration: none;
  display: flex;
}

.logo {
  height: 38px;
  margin: 20px 20px;
}

.sidebar .logo {
  margin: 10px 0 0 10px;
  height: 33px;
}

.header-links a {
  color: #333;
  margin-right: 20px;
  text-decoration: none;
  font-weight: 500;
}

.header-links button {
  padding: 6px 12px;
  background-color: #017bfe;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

.header-links button:hover {
  background-color: #010326;
}

/* Language Selection Section */
.language-selection {
  text-align: center;
  padding: 80px 20px;
}

.language-options button {
  margin: 15px;
  background-color: #cbd5e0;
  color: #1a202c;
  border: 1px solid #1a202c;
  border-radius: 30px;
  font-weight: 500;
}

.language-options button:hover {
  background-color: #2f855a;
  color: white;
}

/* Content Section */
.content-section {
  background-color: #ffde67;
  color: #010326;
  padding: 80px 20px;
  text-align: center;
}

.section-highlight {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.section-highlight h2 {
  font-size: 4rem;
  margin-bottom: 10px;
}

.section-highlight p {
  line-height: 1.5;
  margin-bottom: 30px;
}

.section-highlight img {
  width: 100%;
  height: auto;
  border-radius: 12px;
  margin-bottom: 30px;
}

/* Method Section */
.method {
  padding: 80px 20px;
  background-color: #1a202c;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: white;
}
.method img {
  width: 90%;
  height: auto;
  border-radius: 12px;
  overflow: hidden;
}

main .method h2 {
  font-size: 4rem;
  background-image: linear-gradient(
    to right,
    #e9b026,
    #ff8830,
    #ff5951,
    #ff227a,
    #e500a8,
    #d400b4,
    #be00c0,
    #a200cd,
    #c300b2,
    #d70098,
    #e20081,
    #e62e6e
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 0 10px 0;
}

.method p {
  margin-bottom: 30px;
  line-height: 1.4;
  color: #fff;
  font-size: 1rem;
  text-align: left;
}

.method > p {
  max-width: 60%;
  text-align: center;
  font-size: var(--font-size-md);
}

.method-stats {
  display: flex;
  gap: var(--spacing-lg);
  margin-top: var(--spacing-lg);
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.statcontain {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(29%, 1fr));
  gap: 3%;
  width: 85%;
}

.home .stat h3 {
  font-size: var(--font-size-lg);
  color: var(--color-white);
  font-weight: var(--font-weight-bold);
}

/* Feature Breakout Section */
.features {
  padding: var(--spacing-lg) 0;
  background-color: var(--color-secondary);
  color: var(--color-text-light);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

main .features h2 {
  color: var(--color-accent);
  font-size: var(--font-size-xl);
  background-image: var(--gradient-primary);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 10px 0 0;
}

.features h2 + p {
  max-width: 60%;
  text-align: center;
  margin-bottom: 30px;
  font-size: var(--font-size-md);
  color: var(--color-text-light);
}

.feature-contain {
  display: flex;
  justify-content: center;
  gap: 25px;
  flex-wrap: wrap;
}

.feature-item {
  color: #999;
  max-width: 400px;
  padding: 0 10px;
  line-height: 1.3;
}

.feature-item img {
  width: 100%;
  border-radius: 12px;
  margin-bottom: 0;
}

/* Testimonials Section */
.testimonials {
  padding: 80px 20px;
  background-color: #edf2f7;
  text-align: center;
}

main .testimonials h2 {
  font-size: 4rem;
  margin-bottom: 40px;
}

.testimonials-content {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.testimonials-content img {
  width: 100px;
}

.testimonial {
  max-width: 400px;
  background-color: #fff;
  padding: 25px;
  border-radius: 12px;
  /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); */
  font-weight: 400;
}

/* Join Us Section */
.join-us {
  padding: 80px 20px;
  background-image: linear-gradient(
    to left bottom,
    #e9b026,
    #ff8830,
    #ff5951,
    #ff227a,
    #e500a8,
    #d400b4,
    #be00c0,
    #a200cd,
    #c300b2,
    #d70098,
    #e20081,
    #e62e6e
  );
  text-align: center;
  color: white;
  font-weight: 500;
}

.join-us h2 {
  font-size: 3.2rem;
  max-width: 100%;
  margin: 0 auto;
  color: #fff;
}

.join-options button {
  background-color: #e5f0e5;
  color: #2f855a;
  border-radius: 30px;
  border: none;
  margin: 15px;
  font-weight: 500;
}

/* Footer */
.footer {
  background-color: #1a202c;
  color: white;
  padding: 10px 0 5px;
}

.footer-links {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: #fff;
}

.footer-column ul {
  list-style: none;
  padding: 0;
  font-weight: var(--font-weight-regular);
}

.footer-column ul li {
  margin-bottom: var(--spacing-xs);
}

.footer-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  color: var(--color-text-light);
}

.footer-bottom p {
  font-size: var(--font-size-xs);
  margin-right: 2%;
}

.social-icons a {
  margin-right: var(--spacing-sm);
  color: var(--color-text-light);
}

.social-icons img {
  width: 28px;
  height: 28px;
}

/* Hero Section Styles */
.hero-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: var(--spacing-lg) var(--spacing-xl);
  background-color: var(--color-background);
  flex-direction: row;
  gap: var(--spacing-gap);
}

.hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 30%;
  gap: 5%;
}

.hero-content h1 {
  font-size: 4rem;
  line-height: 1.1;
  color: #010326;
  margin: 0;
}

.hero-content .highlight {
  color: #2f855a;
}

.hero-content p {
  font-size: 1rem;
  margin-bottom: 0px;
  color: #2d3748;
  font-weight: 400;
  text-align: left;
}

.hero-content p + p {
  margin-bottom: 30px;
}

.cta-button {
  padding: 18px 36px;
  background-color: #010326;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #017bfe;
}

.hero-image {
  flex: 1;
}

.hero-image img {
  max-width: 100%;
  border-radius: 12px;
}

.auth-links .login-btn,
.auth-links .signup-btn,
button .signup-btn {
  margin-left: 15px;
  text-decoration: none;
  padding: 8px 15px;
  border-radius: 4px;
  font-weight: 600;
  text-decoration: none;
}

button .signup-btn {
  color: #1a202c;
  font-size: 1.2rem;
}

button .signup-btn:hover {
  color: #fff !important;
}

.auth-links .login-btn {
  color: #e5209e;
  border: 1px solid #e5209e;
}

.auth-links .signup-btn {
  background-color: #e5209e;
  color: #fff;
}

.auth-links .signup-btn:hover {
  background-color: #e5209e;
} /* General Styles for Mobile Responsiveness */

/* Original CSS with Updates */

/* Home Button */
.home button {
  padding: 10px 20px;
  background-color: #1a202c;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}
.home button a {
  color: #fff;
}

.home button:hover a,
.home button:hover a:hover {
  color: #fff;
}

.home button:hover {
  background-color: #e5209e;
  color: #fff;
}

/* Home Text Align */
.home {
  text-align: center;
  padding-top: 30px;
}

.home h1,
.home h2,
.home h3 {
  color: #1a202c;
  font-weight: 700;
  line-height: 1.2;
}

.feature-item h3 {
  color: #fff;
}

.home h2 {
  margin: 0;
}

.highlight {
  color: #2f855a;
}

/* Navbar Styles */
main .header {
  background-color: #fff;
  color: #010326;
  padding: 10px 40px;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #010326;
  padding-right: 10px;
}

.header-links a {
  color: #333;
  margin-right: 20px;
  text-decoration: none;
  font-weight: 500;
}

.header-links button {
  padding: 6px 12px;
  background-color: #017bfe;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

.header-links button:hover {
  background-color: #010326;
}

/* Language Selection Section */
.language-selection {
  text-align: center;
  padding: 80px 20px;
}

.language-options button {
  margin: 15px;
  background-color: #cbd5e0;
  color: #1a202c;
  border: 1px solid #1a202c;
  border-radius: 30px;
  font-weight: 500;
}

.language-options button:hover {
  background-color: #2f855a;
  color: white;
}

/* Content Section */
.content-section {
  background-color: #ffde67;
  color: #010326;
  padding: 80px 20px;
  text-align: center;
}

.section-highlight {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.section-highlight h2 {
  font-size: 4rem;
  margin-bottom: 10px;
}

.section-highlight p {
  line-height: 1.5;
  margin-bottom: 30px;
}

.section-highlight img {
  width: 100%;
  height: auto;
  border-radius: 12px;
  margin-bottom: 30px;
}

/* Method Section */
.method {
  padding: 80px 20px;
  background-color: #1a202c;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: white;
}
.method img {
  width: 90%;
  height: auto;
  border-radius: 12px;
  overflow: hidden;
}

main .method h2 {
  font-size: 4rem;
  background-image: linear-gradient(
    to right,
    #e9b026,
    #ff8830,
    #ff5951,
    #ff227a,
    #e500a8,
    #d400b4,
    #be00c0,
    #a200cd,
    #c300b2,
    #d70098,
    #e20081,
    #e62e6e
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 0 10px 0;
}

/* Footer */
.footer {
  background-color: #1a202c;
  color: white;
  padding: 10px 0 5px;
}

.footer-links {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: #fff;
}

.footer-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  color: #edf2f7;
}

/* media queries */

@media (max-width: 768px) {
  .home h1,
  .home h2,
  .home h3 {
    font-size: 1.5rem;
  }

  .home button {
    padding: 8px 16px;
    font-size: 1rem;
  }

  .header-links a {
    margin-right: 10px;
    font-size: 0.9rem;
  }

  .header-links button {
    font-size: 12px;
    padding: 4px 8px;
  }

  .language-selection {
    padding: 40px 15px;
  }

  .language-options button {
    font-size: 0.9rem;
    padding: 10px 20px;
  }

  .content-section,
  .method,
  .features,
  .testimonials,
  .join-us {
    padding: 40px 15px;
  }

  .section-highlight {
    flex-direction: column;
  }

  .method img,
  .feature-item img {
    width: 100%;
  }

  .method-stats {
    flex-direction: column;
    gap: 30px;
  }

  .stat {
    max-width: 100%;
  }

  main .features h2,
  main .method h2,
  main .content-section h2,
  main .testimonials h2,
  main .join-us h2 {
    font-size: 3rem;
  }

  .features h2 + p {
    max-width: 90%;
    margin: 0 auto 20px auto;
    font-size: 1rem;
  }

  .feature-contain {
    flex-direction: column;
    gap: 20px;
  }

  .feature-item {
    max-width: 100%;
    margin: 0 auto;
  }

  .testimonials-content {
    flex-direction: column;
  }

  .hero-section {
    flex-direction: column;
    padding: var(--spacing-md) var(--spacing-sm);
    gap: var(--spacing-sm);
  }

  .hero-content {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }

  .hero-content h1 {
    font-size: 3.5rem;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .cta-button {
    font-size: 1rem;
    padding: 12px 24px;
  }

  .hero-image img {
    max-width: 100%;
  }

  .footer-links {
    flex-direction: column;
    text-align: center;
  }

  .footer-column ul {
    padding: 0;
    margin-bottom: 20px;
  }

  .footer-bottom {
    flex-direction: column;
    margin-top: 20px;
  }

  .method-stats {
    margin-top: 0;
  }

  .statcontain {
    flex-direction: column;
    width: 100%;
    margin-top: 0;
  }

  .statcontain {
    grid-template-columns: 1fr !important;
  }

  .method-stats {
    flex-direction: column;
    gap: 20px;
  }
}

/* Section Header */
.method-section-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 5%;
}

.method-section-header .stat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  gap: 24%;
  align-content: center;
  text-align: left;
}

.method-section-icon {
  font-size: 3rem;
  color: #e5209e; /* adjust color as needed */
  margin-bottom: 10px;
}

/* Optionally, adjust h2/h3 margins within the header */
.method-section-header h2,
.method-section-header h3 {
  margin: 0;
  width: 100%;
}
