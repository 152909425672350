/* Auth.css */

.auth-container {
  margin: 120px auto 40px;
  width: 100%;
}

.auth-container .form-group input,
.auth-container .form-group textarea,
.auth-container .form-group select {
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  padding: 15px;
}

.auth-container form,
.auth-innner-container {
  width: 30%;
  margin: 0 auto;
  padding: 0;
  min-width: 320px;
}

.forgot-password-section form {
  width: 100%;
  min-width: auto;
}

.auth-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.auth-buttons button {
  width: 100%;
  margin: 10px 0;
  padding: 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}

.auth-buttons button:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  opacity: 0.9;
}

.google-button {
  background-color: #db4437;
}

.facebook-button {
  background-color: #3b5998;
}

.twitter-button {
  background-color: #1da1f2;
}

.microsoft-button {
  background-color: #2f2f2f;
}

.email-button,
.email-signup-button {
  background-color: #4285f4;
}

.auth-buttons button .svg-inline--fa {
  margin-right: 10px;
}

/* Password Reset Styles */
.password-reset {
  margin-top: 20px;
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 6px;
}

.password-reset h3 {
  margin-bottom: 10px;
  color: #333;
}

.password-reset form {
  display: flex;
  flex-direction: column;
}

.password-reset input[type="email"] {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.password-reset button {
  padding: 10px;
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.password-reset button:hover {
  background-color: #3367d6;
}

/* Message Styles */
.reset-message,
.error-message {
  margin-top: 10px;
  border-radius: 4px;
  font-size: 14px;
}

.reset-message {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

/* Loading State Styles */
.auth-buttons button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.auth-buttons button span {
  margin-left: 10px;
}

.auth-container .form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
}

.form-group label {
  font-weight: bold;
  color: #333;
}

.form-group input {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  transition: all 0.2s ease;
}

.form-group input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.1);
}

.auth-button {
  background-color: #007bff;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  transition: background-color 0.2s;
}

.auth-button:hover {
  background-color: #0056b3;
}

.auth-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.forgot-password-link {
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
  margin-top: 16px;
  display: inline-block;
  cursor: pointer;
}

.forgot-password-section {
  margin-top: 20px;
  padding-top: 20px;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-out;
}

.forgot-password-section.active {
  max-height: 200px;
  overflow: visible;
}

.error-message {
  color: #dc3545;
  background: #fde8e8;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.success-message {
  color: #0f5132;
  background: #d1e7dd;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 20px;
}
