.third-pane {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background-color: #fff;
  /* box-shadow: -2px 0 115px rgba(0, 0, 0, .2); */
  padding: 0 20px 20px;
  box-sizing: border-box;
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);
  color: #000;
  text-align: left;
  border-left: 1px solid #eee;
  z-index: 10000;
}

.third-pane .label-container {
  display: flex;
  align-items: flex-start;
}

.third-pane .percent-change.negative-change {
  color: white;
    background: rgb(204, 0, 0);
}

.third-pane .percent-change.positive-change {
  color: white;
  background: #00aa00;
}

.third-pane .average-score label {
  font-size: 18px;
  font-weight: bold;
  display: block;
  text-align: left;
  color: #000;
  text-transform: none;
  margin-top: -5px;
}

.third-pane .heuristic {
  padding: 20px 20px 10px;
  border-radius: 10px;
  margin-bottom: 15px;
  color: #fff;
}

.third-pane .heuristic h3 {
  color: #fff;
  font-size: 12px;
  border-bottom: 1px solid #fff;
  padding: 0 0 10px 20px;
  width: 110%;
  margin: -9px 0 20px -20px;
}

.third-pane .heuristic label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
  color: #fff;
}

.third-pane h3 {
  margin: 0 0 10px 0;
  font-size: 18px;
}

.relaese-name {
  display: block;
}

.third-pane .notes {
  margin-bottom: 20px;
  background: lightyellow;
  padding: 20px;
  border-radius: 20px;
}

.third-pane .notes p {
  font-family: courier new;
  font-weight: 500;
}

.notes-slider {
  display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 10px;
}


.note-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.note-text {
  margin: 0 10px;
  font-size: 1em;
  line-height: 1.4em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nav-arrow {
  cursor: pointer;
  font-size: 1.5em;
}

.nav-arrow.disabled {
  cursor: not-allowed;
    opacity: 0.5;
    z-index: -1;
}

.notesControls{
  justify-content: space-evenly;
  margin-top: 10px;
  font-size: 12px;
  color: #555;
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: row;
}

.notesControls  svg {
  font-size:13px;
  margin:0 5px;
}

.notesHeader {
  display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: baseline;
    flex-direction: row;
}

.note-indicator {
  font-size: 1em;
  font-weight: bold;
  user-select: none;
}

.third-pane ul {
  padding: 0;
  margin: 0;
}

.third-pane p,
.third-pane li {
  font-size: 13px;
  margin: 0 0 10px 0;
  list-style: none;
}

.third-pane-open .third-pane {
  transform: translateX(0);
  overflow-x: hidden;
}

.close-third-pane {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #888;
  z-index: 1;
}

.third-pane .score {
  border: 1px solid #888;
  border-radius: 10px;
  padding: 10px 0;
  text-align: center;
}

.third-pane .average-score {
  border-bottom: 1px solid #dddd;
  margin-left: -20px;
  padding: 20px 0 10px 10px;
  width: 111%;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  background: #fff;
}

.third-pane .score-container {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  margin-bottom: 10px;
}

.third-pane .scoreValue {
  color: #fff;
  font-weight: bold;
  font-size: 18px;
}

.third-pane .score h4 {
  font-size: 52px;
  margin: 0;
}

.close-third-pane:hover {
  color: #000;
}
