.spider-charts-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.spider-chart {
  max-width: 100%;
  max-height: 80vh;
  margin: 55px 30px 0;
  position: relative;
}

.sidebar-toggle {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
}

.chart-container {
  position: relative;
}

.heuristic-label {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  color: black;
  width: 150px; /* Increased width to allow for more text per line */
  pointer-events: none;
  line-height: 1.2;
  max-height: 2.4em; /* Limit to two lines */
  overflow: hidden;
}

.heuristic-word {
  display: block;
}

.custom-tooltip {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  pointer-events: none; /* Prevent mouse events on the tooltip */
  transform: translate(-50%, 0%); /* Position above the point */
  z-index: 10; /* Ensure it appears above other elements */
  border-radius: 10px;
  width: 200px;
  font-size: 10px;
  font-weight: bold;
}

.chart-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.metrics {
  display: flex;
  background: #fff;
  z-index: 1;
  padding: 15px 0px 5px;
  align-items: flex-start;
  justify-content: center;
  align-content: flex-start;
  border-bottom: 1px solid #ddd;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.Overview .metrics,
.Compare .metrics {
  flex-direction: row;
}

.metrics .average-score {
  transform: scale(1);
}

.third-pane-open.Overview .metrics .average-score {
  transform: scale(90%);
  width: 16%;
}

.Compare .metrics {
  position: static;
}

.metrics h1 {
  font-size: 1.3rem;
}

.sidebar-open .metrics {
  left: 280px;
}

.average-score {
  font-weight: normal;
  margin: 0 7px 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: flex-start;
  width: 170px;
}

.average-score.compare-average-score {
  margin: 0;
  width: 100%;
}

.metrics .compare-average-score .label-container > h1 {
  font-size: 1.3rem;
  color: #000;
  width: 80%;
  white-space: nowrap;
}

.average-score label {
  font-size: 12px;
  font-weight: bold;
  display: block;
  text-align: left;
  text-transform: uppercase;
  color: #010326;
}

.scoreValue {
  color: #333;
  font-weight: bold;
  font-size: 38px;
}

.label-container {
  display: flex;
  align-items: center;
}

.score-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.percent-change {
  color: #fff;
  border-radius: 10px;
  font-size: 12px;
  display: block;
  font-weight: bold;
  margin-left: 3px;
  padding: 2px 5px;
  margin-top: 2px;
  display: flex;
  align-items: center;
}

.color-square {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 100%;
}

.percent-change svg {
  margin-right: 3px;
}

.percent-change.positive-change {
  color: rgb(7, 156, 7);
  background: rgba(72, 232, 72, 0.3);
}

.percent-change.negative-change {
  color: rgb(204, 0, 0);
  background: rgba(204, 0, 0, 0.3);
}

.percent-change.no-change {
  color: #999;
  background: #eee;
  width: 80px;
}

/* For the homepage, where we might have multiple scores */
.spider-chart .average-score {
  display: block;
  text-align: right;
}

.scores-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.spider-chart .scores-container {
  text-align: right;
}

#chartjs-tooltip {
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 3px;
  padding: 10px;
  opacity: 0;
  position: absolute;
  pointer-events: none;
  transition: all 0.1s ease;
}

#chartjs-tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
}
