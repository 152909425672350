.co-pilot-chat {
  position: fixed;
  bottom: 150px;
  right: 20px;
  width: 25%;
  max-height: 80%;
  min-height: 30%;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: height 0.5s ease-in-out;
  min-width: 380px;
  z-index: 1;
}

.close-chat-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #fff;
  z-index: 1;
}

.close-chat-button:hover {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  z-index: 1;
}

.chat-header {
  /* background-color: #007bff; */
  background-image: linear-gradient(
    to right,
    #e9b026,
    #ff8830,
    #ff5951,
    #ff227a,
    #e500a8,
    #d400b4,
    #be00c0,
    #a200cd,
    #c300b2,
    #d70098,
    #e20081,
    #e62e6e
  );
  color: white;
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-attachment: fixed;
  background-size: cover;
}

.welcome-message {
  text-align: left;
  color: #333;
  font-size: 0.9rem;
  line-height: 1.4;
  margin-bottom: 20px;
}

.timestamp {
  font-size: 0.8em;
  color: #000;
  display: block;
  margin-top: 10px;
  width: 100%;
  text-align: right;
}

.chat-header h3 span {
  font-size: 0.6em;
  color: #f0f0f0;
  font-weight: normal;
}

.default-prompts {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
}

.prompt-button {
  padding: 8px 12px;
  background: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  text-align: left;
  transition: background 0.2s;
}

.prompt-button:hover {
  background: #e0e0e0;
}

.chat-messages {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
}

.message {
  margin-bottom: 10px;
}

.message.user {
  text-align: left;
  color: #be00c0;
  background: hsl(300deg 100% 53.33% / 6.67%);
  padding: 3px 20px 3px 20px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: 0;
  max-width: 230px;
  font-weight: 500;
  line-height: 1.3;
}

.message.assistant {
  text-align: left;
  color: #333;
  font-size: 0.9rem;
  line-height: 1.4;
}

.usely-logo {
  height: 40px;
  margin-right: 10px;
}

.chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
}

.chat-input input {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.chat-input button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  margin-left: 5px;
  cursor: pointer;
  border-radius: 4px;
}

.chat-input button:hover {
  background-color: #0056b3;
}

.message.assistant h1 {
  font-size: 1.5em;
}

.message.assistant h2 {
  font-size: 1.3em;
  color: #555;
}

.message.assistant h3 {
  font-size: 1.2em;
  color: #777;
}

.message.assistant ul {
  margin: 10px 0;
  padding-left: 20px;
}

.message.assistant li {
  margin-bottom: 5px;
}

.message.assistant p {
  margin: 0 0 15px;
}

.assistant-message {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: nowrap;
  gap: 15px;
  margin-top: 10px;
}

.assistant-message .logo {
  height: 28px;
  margin: 5px 0 0 0;
}

.loading-dots {
  display: inline-flex;
  gap: 5px;
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  animation:
    wave 1.3s linear infinite,
    colorChange 3s linear infinite;
}

.dot:nth-child(2) {
  animation-delay:
    -1.1s,
    -1s;
}

.dot:nth-child(3) {
  animation-delay:
    -0.9s,
    -2s;
}

@keyframes colorChange {
  0%,
  100% {
    background: #e9b026;
  }
  8.33% {
    background: #ff8830;
  }
  16.66% {
    background: #ff5951;
  }
  25% {
    background: #ff227a;
  }
  33.33% {
    background: #e500a8;
  }
  41.66% {
    background: #d400b4;
  }
  50% {
    background: #be00c0;
  }
  58.33% {
    background: #a200cd;
  }
  66.66% {
    background: #c300b2;
  }
  75% {
    background: #d70098;
  }
  83.33% {
    background: #e20081;
  }
  91.66% {
    background: #e62e6e;
  }
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-4px);
  }
}

.co-pilot-chart-container {
  width: 100%;
  margin: 20px 0 40px;
  padding: 0;
  background: white;
  border-radius: 8px;
  position: relative;
  left: -20px;
}

.co-pilot-chart-container .chart-controls {
  display: flex;
  flex-direction: row-reverse;
  margin-right: -20px;
}

.chart-type-selector {
  position: relative;
  right: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  font-size: 14px;
  z-index: 1;
}
