.data-entry-container {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 5px;
  width: 55%;
  margin-bottom: 10%;
}

.data-entry-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 20px;
}

.header-input {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.header-input label {
  margin-right: 10px;
  font-weight: bold;
}

.header-input input[type="checkbox"] {
  width: auto;
  margin-right: 5px;
}

.header-input input {
  width: 60px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.data-entry-form {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.dataset-container {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 5px;
}

.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.input-container label {
  flex: 1;
  margin-right: 10px;
}

.input-container input {
  width: 60px;
  padding: 5px;
}

.generate-data-btn {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
  margin-right: 10px;
}

.generate-data-btn:hover {
  background-color: #45a049;
}

.form-section h3 {
  font-size: 20px;
  background: #f1f1f1;
  margin: 10px 0;
  position: fixed;
  top: -10px;
  left: 18%;
  padding: 15px 0;
  right: 0;
  color: #333;
  transition: left 0.3s ease-in-out;
}

.sidebar-closed .form-section h3 {
  left: 0;
}

.form-group {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 10px;
  flex-direction: column;
  border-radius: 4px;
}

.form-group-header {
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  background: #f8f9fa;
  border-radius: 10px;
  flex-direction: row-reverse;
}

.chevron-icon {
  margin-right: 10px;
  color: #666;
}

.form-group.expanded .chevron-icon {
  transform: rotate(-180deg);
}

.form-group.collapsed .chevron-icon {
  transform: rotate(90deg);
}

.form-group-content {
  padding: 15px;
  transition: max-height 0.3s ease-out;
  overflow: hidden;
}

.form-group.collapsed .form-group-content {
  display: none;
}

.form-group label {
  flex: 1;
  margin-right: 15px;
  text-align: left;
  font-weight: bold;
  margin: 0;
}

.form-group input {
  width: 80%;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 15px;
}

.submit-button:hover {
  background-color: #0056b3;
}

.year-selector {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.year-selector label {
  margin-right: 10px;
  font-weight: bold;
}

.year-selector select {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Styles for the menu in the DataEntryForm component */
.release-dropdown {
  position: relative;
}

.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  width: 120px;
}

.flyout-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  font-size: 14px;
}

.flyout-menu-item {
  display: flex;
  justify-content: space-evenly;
  padding: 5px 0;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
}

.delete-release-icon {
  color: #ccc;
  cursor: pointer;
  font-size: 12px;
  visibility: hidden;
}

.delete-release-icon:hover {
  color: #c00;
}

.flyout-menu-item:hover .delete-release-icon {
  visibility: visible;
}

.flyout-menu-item span {
  flex-grow: 1;
  display: block;
  padding: 3px 5px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 600;
  margin-right: 10px;
}

.release-dropdown .flyout-menu {
  max-height: 200px;
  overflow-y: auto;
  text-align: left;
  height: 200px;
  min-height: 50px;
}

.flyout-menu-item span:hover {
  background: #eee;
}
/* Styles for the menu in the DataEntryForm component */
.release-dropdown,
.page-dropdown {
  position: relative;
}

.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  width: 210px;
}
.dropdown-header > span {
  margin-right: 10px;
}

.flyout-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.flyout-menu-item {
  display: flex;
  justify-content: space-evenly;
  padding: 5px 0;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
}

.delete-release-icon,
.delete-page-icon {
  color: #ccc;
  cursor: pointer;
  font-size: 12px;
  visibility: hidden;
}

.delete-release-icon:hover,
.delete-page-icon:hover {
  color: #c00;
}

.flyout-menu-item:hover .delete-release-icon,
.flyout-menu-item:hover .delete-page-icon {
  visibility: visible;
}

.flyout-menu-item span {
  flex-grow: 1;
  display: block;
  padding: 3px 5px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 600;
  margin-right: 10px;
}

.release-dropdown .flyout-menu,
.page-dropdown .flyout-menu {
  overflow-y: auto;
  text-align: left;
  height: 200px;
  min-height: 50px;
  width: 210px;
  margin-top: 5px;
}

.page-dropdown .flyout-menu {
  height: 360px;
}

.flyout-menu-item span:hover {
  background: #eee;
}

.child-page-item {
  padding-left: 15px;
  font-style: italic;
}

.flyout-menu-item.nested-item {
  font-size: 0.9em;
  border-left: 1px dashed #ddd;
  padding-left: 10px;
  margin-left: 8px;
}
/* notes */
.notes-container {
  width: 100%;
}
.note-input-row {
  display: flex;
  margin-bottom: 10px;
}
.input-group {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  margin-bottom: 10px;
  justify-content: space-between;
}

.notes-container .note-input-row .heuristic-notes {
  flex: 1;
  min-height: 60px;
  padding: 5px;
  resize: vertical;
}

.note-actions {
  display: flex;
  flex-direction: row-reverse;
}

/*links*/

.link-input-row {
  display: flex;
  margin-bottom: 4px;
}

.form-group .link-input-row input {
  display: flex;
  flex-direction: row;
  margin-left: 127px;
}

.links-section {
  margin-top: 8px;
}

.link-row {
  display: flex;
  gap: 8px;
  margin-bottom: 4px;
}

.link-row input {
  flex: 1;
  padding: 4px;
}

.link-actions {
  display: flex;
  gap: 4px;
}

.icon-btn {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 4px;
}

.icon-btn:hover {
  color: #007bff;
}

.icon-btn:hover .fa-trash {
  color: #ff4444;
}

/* Add to DataEntryForm.css */
.links-container.empty {
  display: flex;
  flex-direction: row-reverse;
}

.links-container-add-button {
  display: flex;
  justify-content: flex-end;
}

.form-group .input-group textarea {
  color: #000;
  border-radius: 4px;
  padding: 15px;
  border: 1px solid #ddd;
}

.initial-add-link,
.initial-add-note {
  background: none;
  border: 1px dashed #666;
  color: #666;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
}

.initial-add-link:hover,
.initial-add-note:hover {
  background: #f5f5f5;
  color: #007bff;
  border-color: #007bff;
}

.link-input-row {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: row;
}

.link-input-row input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.link-actions {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-content: center;
  align-items: center;
}

form .link-actions button {
  margin-top: 0;
}

.icon-btn {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 4px;
}

.icon-btn:hover {
  color: #007bff;
}

.icon-btn:hover .fa-trash {
  color: #ff4444;
}

.settings-container {
  position: fixed;
  display: block;
  top: 11px;
  right: 5px;
}

.settings-icon {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
}

.settings-icon:hover {
  background: #f5f5f5;
  color: #007bff;
}

.settings-flyout {
  position: absolute;
  top: 100%;
  right: 20%;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 200px;
}

.settings-flyout button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 8px;
  border: none;
  background: none;
  cursor: pointer;
  color: #333;
}

.settings-flyout button:hover {
  background: #f5f5f5;
}

.form-actions {
  position: fixed;
  bottom: 0;
  background: #fff;
  left: 0;
  right: 0;
  border-top: 1px solid #eee;
  padding: 10px 0;
}

.form-actions .submit-button {
  align-self: flex-start;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  left: 91%;
  position: sticky;
}

.form-actions .submit-button:hover {
  background-color: #056edf;
}

.search-container input {
  border: 0 none;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
  width: 95%;
  padding: 3px;
}

.search-input-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 0;
  padding: 10px 0;
  background: #fff;
  border-bottom: 1px solid #ddd;
}

.search-input-container input {
  padding: 5px;
  color: #000;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
}

.clear-search-btn {
  background: none;
  border: none;
  color: #000;
  cursor: pointer;
  padding: 8px;
}

.clear-search-btn:hover {
  background: none;
  border: none;
  color: #0056b3;
  cursor: pointer;
  padding: 8px;
}

.form-actions button.calculate-btn,
.form-actions button.calculateNotes-btn,
.form-actions button.calculateLinks-btn,
.form-actions button.clone-btn {
  background-color: #333;
  color: #fff;
}

.form-actions button.data-entry-btn[disabled] {
  background-color: #eee;
  color: #ccc;
  cursor: not-allowed;
  display: none;
}

.clone-select {
  margin-top: 15px;
  padding: 8px;
  border-radius: 4px;
  width: 200px;
}
