.heuristic-table-container {
  overflow-y: auto;
  height: calc(100vh - 210px); /* Adjust 40px based on your layout */
  margin: 20px 0;
  display: flex;
  flex-direction: column;
}

.heuristic-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 4px;
  font-size: 11px;
  flex-grow: 1;
}

.heuristic-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
}

.heuristic-table th,
.heuristic-table td {
  border: none;
  padding: 8px;
  text-align: left;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.heuristic-table td {
  font-weight: 500;
  font-size: 170%;
  text-align: center;
}

.heuristic-table td:first-child {
  font-weight: bold;
  font-size: 100%;
  text-align: right;
}

.heuristic-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.heuristic-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.heuristic-table tbody tr:hover {
  background-color: #f0f0f0;
}

/* Add CSS styles for the treemap layout */
.heuristic-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.highlighted-row {
  outline: 3px solid #007bff;
  border-radius: 10px;
}

.highlighted-row td.highlighted-cell {
  outline: 3px solid #007bff;
  opacity: 1;
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.4));
  transform: scale(1.1);
  z-index: 1;
  position: relative;
}
