.notes-container {
  padding: 20px;
}

.notes-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin: 20px 20px 30px 30px;
  gap: 0;
}

.notes-grid.all {
  width: 95%;
}

.notes-grid.all h3 {
  font-size: 1rem;
}

.notes-grid.filtered {
  display: block;
  width: 75%;
}

.notes-grid.filtered .note-group {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 0;
}

.notes-controls {
  gap: 1rem;
  padding: 1.5rem;
  background: #fff;
  border-bottom: 1px solid #ddd;
  flex-shrink: 0;
  flex-grow: 1;
  justify-content: space-around;
  align-items: center;
  width: 98%;
  justify-items: center;
  align-content: center;
  display: grid;
  grid-template-columns: 1fr 4fr 2fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 0;
  position: sticky;
  top: 84px;
  z-index: 1;
}

.notes-controls::before,
.notes-controls::after {
  content: "";
  inline-size: 100%;
}

.search-container input.notes-search {
  padding: 0.5rem;
  border: 1px solid #010326;
  border-radius: 4px;
  padding-right: 30px;
}

.notes-filter {
  padding: 0.5rem;
  border: 1px solid #010326;
  border-radius: 4px;
  width: 100%;
}

.note-expanded {
  text-align: left;
  padding: 0 60px;
}

.expand-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.2s;
}

.expand-button:hover {
  opacity: 1;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: lightyellow;
  padding: 2rem;
  border-radius: 8px;
  max-width: 800px;
  width: 50%;
  overflow-y: auto;
  position: relative;
  margin: 0 auto;
  height: 79%;
}

.close-button {
  position: sticky;
  bottom: 100%;
  left: 100%;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.2s;
}

.close-button:hover {
  opacity: 1;
}

.truncated-note {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.full-note {
  white-space: pre-wrap;
  line-height: 1.6;
}

.nav-button {
  position: sticky;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 26px;
  background: lightyellow;
}

.nav-button:hover {
  transform: translateY(-50%) scale(1.1);
}

.nav-button.prev {
  left: 0;
}

.nav-button.next {
  left: 100%;
}

.search-container {
  position: relative;
  width: 100%;
}

.clear-search {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #010326;
  padding: 5px 0 5px 5px;
  display: flex;
  align-items: center;
  opacity: 0.6;
  transition: opacity 0.2s;
  font-size: 22px;
}

.clear-search:hover {
  opacity: 1;
}
