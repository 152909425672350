/* Highlight the logged-in user row */
.logged-in-user-row {
    background-color: #e8f4ff!important; /* Light blue to highlight the logged-in user row */
  }
  
  
  /* Flyout Dropdown Styles */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-toggle {
    background-color: #2196F3;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .dropdown-toggle:hover {
    background-color: #1976D2;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 4px;
    overflow: hidden;
    padding: 0;
    left: 0; /* Adjust for alignment, use right: 0; if needed */
    top: 40px; /* Adjust to position below the button */
  }
  
  .dropdown.open .dropdown-content {
    display: block;
  }
  
  .dropdown-content button {
    background-color: #337ab7;
    color: #ffffff;
    padding: 10px 12px;
    width: 100%;
    border: none;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .dropdown-content button:hover {
    background-color: #286090;
  }
  
  /* User Role Badges */
  .super-admin-badge {
    background-color: #ff00bf; /* Updated color for Super Admin */
    color: white;
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 14px;
    font-weight: 500;
  }
  
  .admin-badge {
    background-color: #4CAF50;
    color: white;
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 14px;
    font-weight: 500;
  }
  
  .user-badge {
    background-color: #007bff; /* Blue for User */
    color: #fff;
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 14px;
    font-weight: 500;
  }

  .researcher-badge {
    background-color: #b02cfc;
    color: white;
    padding: 4px 12px;
    border-radius: 12px;
    font-size: 14px;
    font-weight: 500;
  }
  
  /* Disabled Button Styles */
  button:disabled,
  button:disabled:hover {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
    opacity: 0.6;
  }
  
  /* Table Styles */
  .user-approval-table-container {
    width: 95%;
    margin-top: 20px;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
  }
  
  .user-approval-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
    font-size: 1rem;
  }
  
  .user-approval-table th,
  .user-approval-table td {
    border-bottom: 1px solid #ddd;
    padding: 12px;
    text-align: left;
    border-right: 1px solid #ddd;
  }
  
  .user-approval-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .user-approval-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .user-approval-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .approve-button,
  .unapprove-button,
  .toggle-button,
  .remove-admin-button,
  .delete-user-button {
    padding: 8px 12px;
    margin: 4px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .approve-button {
    background-color: #4caf50; /* Green */
    color: #fff;
  }
  
  .unapprove-button {
    background-color: #f0ad4e; /* Orange */
    color: #fff;
  }
  
  .toggle-button {
    background-color: #337ab7; /* Blue */
    color: #fff;
  }
  
  .remove-admin-button {
    background-color: #d9534f; /* Red */
    color: #fff;
  }
  
  .delete-user-button {
    background-color: #d9534f; /* Red */
    color: #fff;
  }
  
  .approve-button:hover,
  .unapprove-button:hover,
  .toggle-button:hover,
  .remove-admin-button:hover,
  .delete-user-button:hover {
    opacity: 0.9;
  }
  