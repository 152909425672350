.color-picker-container {
  margin-top: 8px;
}

.picker-with-swatches {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.color-input-row {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 8px;
}

.color-input-row input {
  width: 120px;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.swatches {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 8px;
}

.swatch {
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}

.swatch:hover .swatch-tooltip {
  display: block;
}

.swatch-tooltip {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  margin-bottom: 4px;
}

.add-page-container {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  border-radius: 5px;
  width: 55%;
  margin-bottom: 10%;
  margin-left: auto;
  margin-right: auto;
}

.add-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 20px;
}

.add-page-header h3 {
  font-size: 20px;
  background: #f1f1f1;
  margin: 10px 0;
  position: fixed;
  top: -10px;
  left: 18%;
  padding: 15px 0;
  right: 0;
  color: #333;
  transition: left 0.3s ease-in-out;
}

.sidebar-closed .add-page-header h3 {
  left: 0;
}

.add-page-header h2 {
  margin: 0;
  color: #333;
}

.add-page-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-weight: bold;
  color: #333;
}

.form-group input,
.form-group textarea,
.form-group select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.1);
}

.submit-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 20px;
  align-self: flex-start;
}

.submit-button:hover {
  background-color: #0056b3;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-message {
  color: #dc3545;
  margin-top: 8px;
}

.form-actions {
  bottom: 0;
  background: #fff;
  left: 0;
  right: 0;
  border-top: 1px solid #eee;
  padding: 0;
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  align-content: center;
  align-items: flex-start;
  padding: 10px 0;
}

.form-actions button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin: 0 10px 0 0;
}



.form-actions button svg {
  margin-right: 8px;
}

.form-actions .submit-button:hover {
  background-color: #056edf;
}
